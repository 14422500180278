import '../i18n';

import { PageLoading } from '@amzn/sitc-frontend/components';
import { AuthSessionProvider, MicroAppConfigProvider } from '@amzn/sitc-frontend/contexts';
import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import i18n from 'i18next';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { microAppName } from '../configs/app';
import { paths } from '../constants/paths';
import { getGraphqlEndpoint } from '../graphql/config';
import { appDomainTemplate, MicroAppConfigContext, MicroAppConfigResultType } from '../utils/app';
import { FeatureConfiguration } from './FeatureConfiguration';
import { UniversalHeader } from './UniversalHeader';

const UserLookup = React.lazy(() => import('./UserLookup'));
const SurveyCalendar = React.lazy(() => import('./Survey'));

const StudiosResearchApi: React.FC<MicroAppInitializationProps> = (props) => {
  const { authSession, appId, basePath, stage, featureOverrides } = props;
  const cache = new InMemoryCache({});
  const httpLink = createHttpLink({
    uri: getGraphqlEndpoint(stage),
  });

  const authLink = setContext((setter, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${authSession.idToken}`,
      appid: appId,
    },
  }));

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache,
  });

  return (
    <ThemeProvider theme={backlotLightTheme}>
      <ApolloProvider client={client}>
        <CssBaseline />
        <MicroAppConfigProvider<MicroAppConfigResultType>
          stage={stage}
          appName={microAppName}
          appId={appId}
          appDomainTemplate={appDomainTemplate}
          appLoader={<PageLoading />}
          context={MicroAppConfigContext}>
          <FeatureConfiguration featureOverrides={featureOverrides}>
            <I18nextProvider i18n={i18n}>
              <BrowserRouter basename={basePath}>
                <AuthSessionProvider authSession={authSession}>
                  <UniversalHeader basePath={basePath} />
                  <Suspense fallback="loading...">
                    <Routes>
                      <Route path="/" element={<Navigate replace to={paths.userLookup} />} />
                      <Route path={paths.userLookup} element={<UserLookup />} />
                      <Route path={paths.surveyCalendar} element={<SurveyCalendar />} />
                    </Routes>
                  </Suspense>
                </AuthSessionProvider>
              </BrowserRouter>
            </I18nextProvider>
          </FeatureConfiguration>
        </MicroAppConfigProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default StudiosResearchApi;
