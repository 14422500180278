import { UniversalHeader as StandaloneHeader } from '@amzn/sitc-frontend/components';
import React from 'react';

import { paths } from '../constants/paths';

type Props = {
  basePath: string;
};

export const UniversalHeader = ({ basePath }: Props) => (
  // Use minimal Universal Header.
  <StandaloneHeader
    disableWindowScrollElevation
    productIdentifier="Studios Research"
    productIdentifierPath={basePath}
    headerLinks={[
      { primary: 'User Lookup', path: paths.userLookup },
      { primary: 'Survey Calendar', path: paths.surveyCalendar },
    ]}
  />
);
